<template>
  <div class="round-table position-relative">
    <span class="text-pp"><i class="fas fa-star"></i> ผลงวดที่ผ่านมา</span>
    <table class="table table-sm table-bordered mb-0">
      <thead>
        <tr>
          <th width="30%">หวย</th>
          <th width="30%">งวดวันที่</th>
          <th width="20%">3 ตัวบน</th>
          <th width="20%">2 ตัวล่าง</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items">
          <td>{{ item.note.marketTitle }}</td>
          <td>{{ item.roundDate.date | dispDateTime("DD-MM-YYYY") }}</td>
          <td>{{ item.results.threeNumberTop[0] }}</td>
          <td>{{ item.results.twoNumberBottom[0] }}</td>
        </tr>
        <tr v-if="!items.length">
          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="60"
      :width="60"
      color="#51CE8D"
      loader="dots"
    />
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'

export default {
  name: 'RecentResults',
  props: ['marketId'],
  data() {
    return {
      data: null,
      isLoading: false,
      isFail: false
    }
  },
  computed: {
    items() {
      return this.data || []
    }
  },
  watch: {
    marketId() {
      if(this.marketId) {
        this.getResults()
      }
    }
  },
  methods: {
    getResults() {
      console.log('this.marketId', this.marketId)
      this.isLoading = true
      this.isFail = false
      ReportService.getMarketResults(this.marketId, 5).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.isFail = true
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    // this.getResults()
  }
}
</script>
<style lang="scss" scoped>
.round-table {
  background-color: #FFF;
}
table {
  thead {
    background-color: #028F44;

    tr {
      th {
        font-size: 85%;
        text-align: center;
        border: 1px solid #028F44;
        color: #FFFFFF;
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 85%;
        text-align: center;
        border: 1px solid #028F44;
      }
    }
  }
}
</style>
