<template>
  <div id="page-round-id">
    <div class="py-1">
      <router-link :to="{name:'PlayLotto'}" class="h5 font-weight-normal text-pp"><i class="fas fa-exchange-alt fa-sm"></i> เปลี่ยนหวย</router-link>
    </div>
    <div class="py-1 text-danger">
      เหลือเวลา {{roundTimer}}
    </div>
    <div class="row px-2 position-relative">
      <div class="col-md-8 px-2 mb-5">

        <div>
          <RoundKeyNumbers
            v-if="page=='PageInput'"
            :round="round"
            :rateId="rateId"
            :roundTimer="roundTimer"
            :bills="bills"
            :tableNumbers="tableNumbers"
            :ticket="ticket"
            :soldout="soldoutNumbers"
            @rateId="setRateId"
            @clear="clearData"
            @ticket="updateTicket"
          />

          <RoundConfirm
            v-if="page=='PageTicket'"
            :round="round"
            :ticket="ticket"
            @cancel="cancel"
            @back="back"
            @success="success"
          />

          <loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
            background-color="#EEEEEE"
            :height="60"
            :width="60"
            color="#51CE8D"
            loader="dots"
          />
        </div>

        <RecentTickets class="mt-5" :reload="reloadTicket" @success="reloadTicket=false" />
      </div>
      <div class="col-md-4 px-2">

        <RoundPayrate class="mb-2" />

        <RoundSetNumbers class="mb-2" :data="round" @soldout="setSoldout" />

        <RecentResults :marketId="marketId" />

      </div>
    </div>

    <!-- <SelectRateModal :is-show="isShowRateModal" :rates="rates" @set="setRateId" @close="isShowRateModal = false" /> -->
  </div>
</template>
<script>
import LotteryService from '@/services/LotteryService'

import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'

import RoundKeyNumbers from './components/RoundKeyNumbers'
import RoundConfirm from './components/RoundConfirm'
import RoundCloseNumbers from './components/RoundCloseNumbers'
import RecentResults from './components/RecentResults'
import RecentTickets from './components/RecentTickets'
import RoundSetNumbers from './components/RoundSetNumbers'
import RoundPayrate from '@/views/PlayLotto/components/RoundPayrate'
// import SelectRateModal from './components/SelectRateModal'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'PlayRoundId',
  components: {
    RoundKeyNumbers,
    RoundConfirm,
    Loading,
    RoundCloseNumbers,
    RecentResults,
    RecentTickets,
    RoundSetNumbers,
    RoundPayrate,
    // SelectRateModal
  },
  data() {
    return {
      page: 'PageInput',
      roundId: this.$route.params.roundId,
      round: null,
      rateId: null,
      bills: [],
      tableNumbers: [],
      ticket: {
        queueId: null,
        roundId: this.$route.params.roundId,
        rateId: null,
        remarks: '',
        rows: [],
        uniqueCode: null
      },
      isLoading: true,
      /**
       * ตัวแปรสำหรับนับเวลา
       */
      roundInterval: null,
      roundTimer: null,
      timerInterval: null,
      reloadTicket: false,
      soldoutNumbers: {},
      recentTab: 'ticket',
      // isShowRateModal: false,
      // rates: []
    }
  },
  computed: {
    marketId() {
      return this.round?.marketId
    }
  },
  methods: {
    getRound(silent=false) {

      if(!silent)
        this.isLoading = true

      LotteryService.getRound(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.round = response.data
          // this.rates = response.data?.rates
          this.reloadTicket = true

          /**
           * ตรวจสอบการเปิดอัตราจ่าย
           */
          const rates = response.data?.rates ?? []
          if(!rates.length) {
            throw new Error('ไม่มีอัตราจ่ายที่ใช้งานได้ กรุณาติดต่อเอเย่นต์')
          }

          /**
           * นับเวลาถอยหลัง
           */
          if(this.round.market.marketType === 'lottoset') {
            this.$router.push({name: 'PlayLottosetRoundId', params: { roundId: this.roundId }})
          }else
          if(!silent) {
            console.log('!silent')
            this.roundCountdown()
            this.timerInterval = setInterval(() => {
              this.roundCountdown()
            }, 1000)

            /**
             * ถ้าไม่ได้เลือกอัตราจ่ายให้ขึ้น modal ให้เลือกอัตราจ่าย
             */
            // if(!this.rateId) {
            //   console.log('Show Modal Rate Options')
            //   this.isShowRateModal = true
            // }
          }
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        /**
         * error code 501 = ให้ redirect ไปหน้ารวมหวย
         */
        if(!silent || e?.status===501) {
          cAlert({
            ...e,
            title: 'ผิดพลาด!',
            text: e?.message || 'กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          }, ()=>{
            this.$router.push({name: 'PlayLotto'})
          })
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    roundCountdown() {
      try {
        if(this.round.status === 'Open') {
          if(moment().isBetween(this.round.roundDate.open, this.round.roundDate.close)) {

            const diff = moment(this.round.roundDate.close).diff(moment(), 'milliseconds')
            const duration = moment.duration(diff, 'milliseconds')
            let d = moment.duration(duration).days(),
                h = moment.duration(duration).hours(),
                m = moment.duration(duration).minutes(),
                s = moment.duration(duration).seconds()

            if(d > 0) h = (d*24) + h

            const texts = []
            if(h > 0) {
              if(h < 10)
                texts.push(`0${h}`)
              else
                texts.push(h)
            }

            if(h > 0 || m > 0) {
              if(m < 10)
                texts.push(`0${m}`)
              else
                texts.push(m)
            }

            if(s < 10)
              texts.push(`0${s}`)
            else
              texts.push(s)

            this.roundTimer = texts.join(':')
          }else{

            this.roundTimer = 'ปิดรับ'
            clearInterval(this.timerInterval)
            throw new Error()
          }
        }else{

          clearInterval(this.timerInterval)
          throw new Error()
        }
      }
      catch(e) {

        Swal.fire({
          text: e?.message || 'ปิดรับแล้ว!',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'PlayLotto'})
        })
      }
    },
    setRateId(rateId) {
      this.rateId = rateId
    },
    updateTicket(ticket) {
      this.ticket = ticket
      this.page = 'PageTicket'
    },
    back() {
      this.page = 'PageInput'
    },
    clearData() {
      this.bills = []
      this.tableNumbers = []
      this.ticket.remarks = ''
    },
    cancel() {
      this.clearData()
      this.back()
    },
    success() {

      this.$notify({
        type: 'success',
        title: 'ส่งโพยแล้ว!',
        text: 'รอการตรวจสอบ...',
      })

      this.reloadTicket = true
      this.$store.dispatch('reloadBalance')
      this.clearData()
      this.back()
    },
    setSoldout(data) {
      this.soldoutNumbers = data
    }
  },
  mounted() {
    this.getRound()
    this.roundInterval = setInterval(() => {
      if(!this.$store.state.isProcessTicket) {
        this.getRound(true)
      }
    }, 60000)
  },
  beforeDestroy() {
    console.log('destroy!')
    clearInterval(this.timerInterval)
    clearInterval(this.roundInterval)
  }
}
</script>
<style lang="scss">
#page-round-id {
  padding-bottom: 100px;
}

.round-recent-stat {
  .table {
    border: 0;

    th:first-child, td:first-child {
      border-left: 0;
    }

    th:last-child, td:last-child {
      border-right: 0;
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }

    td {
      font-size: 85%;

      .badge {
        font-weight: normal;
        font-size: 90%;
      }
    }

    thead {
      tr {
        th {
          font-size: 85%;
          // font-weight: normal;
          border-top: 0;
        }
      }
    }
  }
}
</style>
